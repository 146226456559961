import { render, staticRenderFns } from "./NLUTable.vue?vue&type=template&id=3a295ba5&scoped=true&"
import script from "./NLUTable.vue?vue&type=script&lang=js&"
export * from "./NLUTable.vue?vue&type=script&lang=js&"
import style0 from "./NLUTable.vue?vue&type=style&index=0&id=3a295ba5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a295ba5",
  null
  
)

export default component.exports