<template>
  <vs-popup
    classContent="popup-example"
    :title="lang.nlu.edit.popUpTitle[languageSelected]"
    :active.sync="activePopup"
  >
    <div>
      <div class="vx-row">
        <vs-col class="w-2/3">
          <vs-input
            class="mt-2 w-full mb-base"
            v-model="newName"
            :label="lang.nlu.edit.name[languageSelected]"
            :disabled="saving"
          ></vs-input>
        </vs-col>
        <vs-col class="w-1/3">
          <vs-input
            class="mt-2 w-full mb-base"
            v-model="type"
            :label="lang.nlu.edit.type[languageSelected]"
            disabled
          ></vs-input>
        </vs-col>
      </div>
      <div class="vx-row">
        <vs-col class="w-1/2">
          <vs-checkbox v-model="newActive" class="mt-2 mb-5">
            {{ lang.nlu.edit.active[languageSelected] }}
          </vs-checkbox>
        </vs-col>
      </div>

      <vs-divider />

      <div class="vx-row" v-if="nluItem && nluItem.type === 'luis'">
        <vs-col>
          <vs-input
            class="mt-2 w-full mb-base"
            v-model="newConfig.subscriptionKey"
            :label="'Subscription Key'"
            :disabled="saving"
          ></vs-input>
        </vs-col>
      </div>

      <div class="vx-row" v-if="nluItem && nluItem.type === 'watson'">
        <vs-col>
          <vs-input
            class="mt-2 w-full"
            v-model="newConfig.apikey"
            :label="'API key'"
            :disabled="saving"
          ></vs-input>
          <vs-input
            class="mt-2 w-full mb-base"
            v-model="newConfig.url"
            :label="'URL'"
            :disabled="saving"
          ></vs-input>
        </vs-col>
      </div>

      <vs-divider />

      <vs-button class="float-right" @click="save" :disabled="saving">{{
        lang.nlu.edit.saveText[languageSelected]
      }}</vs-button>
      <vs-button
        class="float-right mr-4"
        color="danger"
        @click="cancel"
        :disabled="saving"
        >{{ lang.nlu.edit.cancelText[languageSelected] }}</vs-button
      >
    </div>
  </vs-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'EditNLU',
  components: {},
  props: {
    nluItem: Object
  },
  data() {
    return {
      activePopup: false,
      saving: false,

      newName: '',
      newActive: null,
      newConfig: {}
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    type() {
      if (this.nluItem && this.nluItem.type === 'luis') {
        return 'Microsoft LUIS'
      }
      if (this.nluItem && this.nluItem.type === 'watson') {
        return 'IBM Watson'
      }
      return 'N/A'
    }
  },
  watch: {},
  methods: {
    ...mapActions('credentials', ['UPDATE_NLU']),
    openPopup() {
      this.activePopup = true
      this.updateFields(this.nluItem)
    },
    updateFields(newVal) {
      this.newName = newVal.name
      this.newActive = newVal.active
      if (newVal.type === 'luis') {
        this.newConfig = {
          subscriptionKey: newVal.config.subscriptionKey
        }
      }
      if (newVal.type === 'watson') {
        this.newConfig = {
          apikey: newVal.config.apikey,
          url: newVal.config.url
        }
      }
    },
    cancel() {
      this.activePopup = false
    },
    async save() {
      if (!this.newName || this.newName.trim().length === 0) {
        return this.$vs.notify({
          title: this.lang.nlu.validationErrors.invalidName.title[
            this.languageSelected
          ],
          text: this.lang.nlu.validationErrors.invalidName.text[
            this.languageSelected
          ],
          color: 'warning',
          position: 'top-right'
        })
      }
      this.saving = true
      try {
        const message = {
          body: {
            name: this.newName,
            active: this.newActive,
            config: this.newConfig
          },
          _id: this.nluItem._id
        }
        await this.UPDATE_NLU(message)
        this.$vs.notify({
          title: this.lang.nlu.edit.success.title[this.languageSelected],
          text: this.lang.nlu.edit.success.text[this.languageSelected],
          color: 'success',
          position: 'top-right'
        })
        this.cancel()
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          title: this.lang.nlu.edit.error.title[this.languageSelected],
          text: this.lang.nlu.edit.error.text[this.languageSelected],
          color: 'warning',
          position: 'top-right'
        })
      }
      this.saving = false
    }
  }
}
</script>
