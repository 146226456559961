<template>
  <div id="nlu-table" v-if="nlu">
    <vs-table
      max-items="20"
      pagination
      search
      :data="nlu"
      :no-data-text="lang.general.noDataText[languageSelected]"
    >
      <template slot="thead">
        <vs-th sort-key="name">
          {{ lang.nlu.table.name[languageSelected] }}
        </vs-th>
        <vs-th sort-key="type">
          {{ lang.nlu.table.type[languageSelected] }}
        </vs-th>
        <vs-th sort-key="active">
          {{ lang.nlu.table.active[languageSelected] }}
        </vs-th>
        <vs-th sort-key="_updatedAt">
          {{ lang.nlu.table._updatedAt[languageSelected] }}
        </vs-th>
        <vs-th sort-key="_createdAt">
          {{ lang.nlu.table._createdAt[languageSelected] }}
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.name">{{ tr.name }}</vs-td>
          <vs-td :data="tr.type">
            {{
              tr.type === 'luis'
                ? 'Microsoft LUIS'
                : tr.type === 'watson'
                ? 'IBM Watson'
                : tr.type
            }}
          </vs-td>
          <vs-td
            :class="{ 'text-success': tr.active, 'text-danger': !tr.active }"
            :data="tr.active"
          >
            <feather-icon
              :icon="tr.active ? 'CheckIcon' : 'XIcon'"
              svgClasses="h-5 w-5"
            />
          </vs-td>
          <vs-td :data="tr._updatedAt">{{
            new Date(tr._updatedAt || tr._createdAt) | date
          }}</vs-td>
          <vs-td :data="tr._createdAt">{{
            new Date(tr._createdAt) | date
          }}</vs-td>

          <vs-td>
            <div class="action-buttons">
              <vs-button
                class="with-space"
                @click="edit(tr)"
                icon="create"
                type="flat"
              />
              <vs-button
                @click="remove(indextr)"
                icon-pack="feather"
                icon="icon-trash"
                type="flat"
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'NLUTable',
  props: {
    toggleNLUEdit: Function
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('credentials', ['nlu'])
  },
  methods: {
    ...mapActions('credentials', ['DELETE_NLU']),
    ...mapMutations('credentials', []),
    edit(tr) {
      this.toggleNLUEdit(tr)
    },
    remove: function(indextr) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.nlu.remove.dialog.title[this.languageSelected],
        text: this.lang.nlu.remove.dialog.text[this.languageSelected],
        accept: async () => {
          this.$vs.loading()
          try {
            await this.DELETE_NLU({ _id: this.nlu[indextr]._id })
            this.$vs.notify({
              title: this.lang.nlu.remove.success.title[this.languageSelected],
              text: this.lang.nlu.remove.success.text[this.languageSelected],
              color: 'success',
              position: 'top-right'
            })
            this.$vs.loading.close()
          } catch (error) {
            this.$vs.loading.close()
            this.$vs.notify({
              title: this.lang.nlu.remove.error.title[this.languageSelected],
              text: this.lang.nlu.remove.error.text[this.languageSelected],
              color: 'danger',
              position: 'top-right'
            })
          }
        }
      })
    }
  }
}
</script>

<style type="scss" scoped>
.action-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 19px;
}
.with-space {
  margin-right: 5px;
}
</style>
