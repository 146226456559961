<template>
  <div class="nlu">
    <AddNLU ref="addNLU" />
    <EditNLU ref="editNLU" :nluItem="selected" />
    <vs-row>
      <vs-col vs-lg="12" vs-xs="12">
        <vs-card class="overflow-hidden mb-2">
          <div slot="header">
            <h3>{{ lang.nlu.title[languageSelected] }}</h3>
          </div>
          <vs-row>
            <vs-col>
              <vs-button
                class="float-right mt-2"
                icon="add"
                @click="toggleAddNLU"
              >
                {{ lang.nlu.add.popUpTitle[languageSelected] }}
              </vs-button>
            </vs-col>
            <vs-col vs-w="12">
              <NLUTable :toggleNLUEdit="toggleNLUEdit" />
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import NLUTable from './NLUTable'
import AddNLU from './AddNLU'
import EditNLU from './EditNLU'

import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'NLU',
  components: {
    NLUTable,
    AddNLU,
    EditNLU
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('credentials', ['nlu'])
  },
  methods: {
    toggleAddNLU() {
      this.$nextTick(() => {
        this.$refs.addNLU.openPopup()
      })
    },
    toggleNLUEdit(tr) {
      this.selected = tr
      this.$nextTick(() => {
        this.$refs.editNLU.openPopup()
      })
    },
    ...mapMutations('credentials', ['START_STORE']),
    ...mapActions('credentials', ['GET_NLU'])
  },
  async mounted() {
    await this.GET_NLU()
  }
}
</script>
