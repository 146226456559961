<template>
  <vs-popup
    classContent="popup-example"
    :title="lang.nlu.add.popUpTitle[languageSelected]"
    :active.sync="activePopup"
  >
    <div>
      <div class="vx-row">
        <vs-col class="w-2/3">
          <vs-input
            class="mt-2 w-full mb-base"
            v-model="newName"
            :label="lang.nlu.add.name[languageSelected]"
            :disabled="saving"
          ></vs-input>
        </vs-col>
        <vs-col class="w-1/3">
          <ul class="mt-3 ml-0 pt-4">
            <li>
              <vs-radio v-model="newType" vs-value="luis"
                >Microsoft LUIS</vs-radio
              >
              <vs-radio v-model="newType" vs-value="watson"
                >IBM Watson</vs-radio
              >
            </li>
          </ul>
        </vs-col>
      </div>
      <div class="vx-row">
        <vs-col class="w-1/2">
          <vs-checkbox v-model="newActive" class="mt-2 mb-5">{{
            lang.nlu.add.active[languageSelected]
          }}</vs-checkbox>
        </vs-col>
      </div>

      <vs-divider />

      <div class="vx-row" v-if="newType === 'luis'">
        <vs-col>
          <vs-input
            class="mt-2 w-full mb-base"
            v-model="newConfig.subscriptionKey"
            :label="'Subscription Key'"
            :disabled="saving"
          ></vs-input>
        </vs-col>
      </div>

      <div class="vx-row" v-if="newType === 'watson'">
        <vs-col>
          <vs-input
            class="mt-2 w-full"
            v-model="newConfig.apikey"
            :label="'API key'"
            :disabled="saving"
          ></vs-input>
          <vs-input
            class="mt-2 w-full mb-base"
            v-model="newConfig.url"
            :label="'URL'"
            :disabled="saving"
          ></vs-input>
        </vs-col>
      </div>

      <vs-divider />

      <vs-button class="float-right" @click="save" :disabled="saving">{{
        lang.nlu.add.saveText[languageSelected]
      }}</vs-button>
      <vs-button
        class="float-right mr-4"
        type="flat"
        color="danger"
        @click="cancel"
        :disabled="saving"
        >{{ lang.nlu.add.cancelText[languageSelected] }}</vs-button
      >
    </div>
  </vs-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AddNLU',
  components: {},
  data() {
    return {
      activePopup: false,
      saving: false,

      newName: '',
      newType: '',
      newActive: null,
      newConfig: {}
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    type() {
      if (this.newType === 'luis') {
        return 'Microsoft LUIS'
      }
      return 'N/A'
    }
  },
  watch: {
    newType(newVal) {
      if (newVal === 'luis') {
        this.newConfig = {
          subscriptionKey: '',
          apikey: '',
          url: ''
        }
      } else if (newVal === 'watson') {
        this.newConfig = {
          apikey: '',
          url: ''
        }
      }
    }
  },
  methods: {
    ...mapActions('credentials', ['CREATE_NLU']),
    openPopup() {
      this.activePopup = true
      this.updateFields(this.nluItem)
    },
    updateFields() {
      this.newName = ''
      this.newType = 'luis'
      this.newActive = true
      this.newConfig = {
        subscriptionKey: '',
        apikey: '',
        url: ''
      }
    },
    cancel() {
      this.activePopup = false
    },
    async save() {
      if (!this.newName || this.newName.trim().length === 0) {
        return this.$vs.notify({
          title: this.lang.nlu.validationErrors.invalidName.title[
            this.languageSelected
          ],
          text: this.lang.nlu.validationErrors.invalidName.text[
            this.languageSelected
          ],
          color: 'warning',
          position: 'top-right'
        })
      }
      this.saving = true
      try {
        const message = {
          body: {
            name: this.newName,
            type: this.newType,
            active: this.newActive,
            config: this.newConfig
          }
        }
        await this.CREATE_NLU(message)
        this.$vs.notify({
          title: this.lang.nlu.edit.success.title[this.languageSelected],
          text: this.lang.nlu.edit.success.text[this.languageSelected],
          color: 'success',
          position: 'top-right'
        })
        this.cancel()
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          title: this.lang.nlu.edit.error.title[this.languageSelected],
          text: this.lang.nlu.edit.error.text[this.languageSelected],
          color: 'warning',
          position: 'top-right'
        })
      }
      this.saving = false
    }
  }
}
</script>
